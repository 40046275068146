import Swiper from "swiper";
import { Autoplay } from 'swiper/modules';

Swiper.use([Autoplay])

export default class Logos{
  constructor() {
    this.swiperLogos = document.querySelector('.swiper-logos');
  }

  init() {
    if (!this.swiperLogos) return;
    this.initLogos();
  }

  initLogos() {
    new Swiper(this.swiperLogos, {
      modules: [Autoplay],
      slidesPerView: 3,
      spaceBetween: 24,
      autoplay: {
        "delay": 3000
      },
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 100,
        }
      },
    });
  }
}
