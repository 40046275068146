export default class Blog {
  constructor() {
    this.copyLink = document.querySelector('.copy-link');
  }

  init() {
    if (!this.copyLink) return;
    this.copyLinkToClipboard();
  }

  copyLinkToClipboard() {
    this.copyLink.addEventListener('click', () => {
      const link = window.location.href;
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(link)
          .then(() => {
            const text = this.copyLink.querySelector('span');
            text.textContent = this.copyLink.dataset.copied;
            setTimeout(() => {
              text.textContent = this.copyLink.dataset.copy;
            }, 3000);
          })
          .catch((err) => {
            console.error('Error in copying text: ', err);
          });
      } else {
        console.log('Clipboard API not available');
      }
    });
  }
}
