export default class Hero {
  constructor() {
    this.playButton = document.querySelector('[data-play');
    this.video = document.querySelector('[data-video]');
  }

  init() {
    if (!this.video) return;
    this.playVideo();
  }

  playVideo() {
    this.playButton.addEventListener('click', () => {
      this.video.play();
      this.playButton.dataset.pause = false;
    });

    this.video.addEventListener('click', () => {
      if (this.playButton.dataset.pause === 'false') {
        this.video.pause();
        this.playButton.dataset.pause = true;
      }
    });
  }
}
