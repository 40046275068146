import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

Swiper.use([Navigation])

export default class TextImage {
  constructor() {
    this.swiperTextImage = document.querySelector('.swiper-text-image');
  }

  init() {
    if (!this.swiperTextImage) return;
    this.initTextImage();
  }

  initTextImage() {
    new Swiper(this.swiperTextImage, {
      modules: [Navigation],
      slidesPerView: 1,
      navigation: {
        nextEl: '.text-image-button-next',
        prevEl: '.text-image-button-prev',
      },
    });
  }
}
