import domReady from '@roots/sage/client/dom-ready';
import Header from '@scripts/components/Header';
import Blog from '@scripts/components/Blog';
import Hero from '@scripts/builder/Hero';
import Gallery from '@scripts/builder/Gallery';
import Testminonials from '@scripts/builder/Testimonials';
import Logos from '@scripts/builder/Logos';
import TextImage from '@scripts/builder/TextImage';
import Aos from 'aos';
import ScrollSnap from '@scripts/effects/ScrollSnap';
import ScrollSection from '@scripts/effects/ScrollSection';

domReady(async () => {
  Aos.init({
    duration: 1000,
    offset: 150,
    once: true,
  });

  /* const scrollSnap = new ScrollSnap()

  if(window.innerWidth >= 1024) {
    scrollSnap.init();
  } */
});

domReady(async () => {
  const scrollSection = new ScrollSection();
});

const header = new Header();
header.init();

const blog = new Blog();
blog.init();

const hero = new Hero();
hero.init();

const gallery = new Gallery();
gallery.init();

const testimonials = new Testminonials();
testimonials.init();

const logos = new Logos();
logos.init();

const textImage = new TextImage()
textImage.init()




if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
