export default class Header {
  constructor() {
    this.header = document.querySelector('.header');
    this.hamburger = document.querySelector('.navbar-burger');
    this.menucontent = document.querySelector('.navbar-menu');
    this.navbarclose = document.querySelector('.navbar-close');
  }

  init() {
    if (!this.header && !this.hamburger) {
      return false;
    }

    this.toggleMenu();

    this.closeMenu();
  }

  toggleMenu() {
    this.hamburger.addEventListener('click', () => {
      if (this.menucontent.classList.contains('hidden')) {
        this.menucontent.classList.remove('hidden');
      } else {
        this.menucontent.classList.add('hidden');
      }
    });
  }

  closeMenu() {
    this.navbarclose.addEventListener('click', () => {
      this.menucontent.classList.add('hidden');
    });
  }
}
