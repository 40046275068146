import { gsap, } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollSnap {
    constructor() {
        this.aboutsection = document.querySelector('.about-us-section--desktop');
        this.horizontalSliders = document.querySelector('.horizontal-sliders')
    }

  init() {
    // Gsap Code
    let currentIndex = 0;
    let animating;
    let swipePanels = gsap.utils.toArray(".about-us-section--desktop .text-image--3");
    let padding = swipePanels.length * 750;

    // set z-index levels for the swipe panels
    gsap.set(swipePanels, {
      zIndex: i => i
    });

    // create an observer and disable it to start
    let intentObserver = ScrollTrigger.observe({
      type: "wheel,touch,scroll",
      onUp: () => {
        if(!animating)
          gotoPanel(currentIndex + 1, true);
      },
      onDown: () => {
        if(!animating)
          gotoPanel(currentIndex - 1, false);
      },
      wheelSpeed: -1,
      scrollSpeed: -1,
      tolerance: 100,
      preventDefault: true,
      onPress: self => {
        // on touch devices like iOS, if we want to prevent scrolling, we must call preventDefault() on the touchstart (Observer doesn't do that because that would also prevent side-scrolling which is undesirable in most cases)
        ScrollTrigger.isTouch && self.event.preventDefault()
      }
    });
    intentObserver.disable();

    // handle the panel swipe animations
    function gotoPanel(index, isScrollingDown) {
      animating = true;

      // return to normal scroll if we're at the end or back up to the start
      if ((index === swipePanels.length && isScrollingDown) || (index === -1 && !isScrollingDown)) {
        animating = false;
        intentObserver.disable();
        return
      }

      // target the second panel, last panel?
      let target = isScrollingDown ? swipePanels[index]: swipePanels[currentIndex];
      
      const targetScrollPosition = (
        document.querySelector('.pin-spacer').offsetTop - 
        (window.innerHeight - swipePanels[index].clientHeight) / 2
      ) + ((padding / (swipePanels.length-1)) * index);

      gsap.to(window, {
        scrollTo: {
          y: targetScrollPosition
        },
        duration: 2,
        onComplete: () => {
         animating = false;
        }
      });

      currentIndex = index;
    }

    let scrollTween2 = gsap.to(swipePanels, {
      xPercent: -100 * (swipePanels.length - 1),
      ease: "none", // <-- IMPORTANT!
      scrollTrigger: {
        trigger: ".about-us-section--desktop",
        pin: true,
        scrub: 0.1,
        start: "50% 50%",
        onEnter: (self) => {
          intentObserver.enable();
        },
        onEnterBack: () => {
          intentObserver.enable();
        },
        end: "+=" + padding,
      }
    });
  }
}