import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ScrollSection {
    constructor() {
        this.containerSelector = '.scroll-container';
        this.contentSelector = '.scroll-content';

        this.sections = gsap.utils.toArray(this.contentSelector);

        if (this.sections.length > 0) {
            this.initScrollAnimation();
        } else {
            return false;
        }
    }
    initScrollAnimation() {
        gsap.to(this.sections, {
            xPercent: -100 * (this.sections.length - 1),
            ease: "none",
            scrollTrigger: {
                trigger: this.containerSelector,
                pin: true,
                scrub: 1,
                snap: {
                    snapTo: 1 / (this.sections.length - 1),
                    duration: 0.2,
                    ease: "power1.inOut",
                    delay: 0
                },
                end: () => "+=" + document.querySelector(this.containerSelector).offsetWidth
            }
        });
    }
}