import fslightbox from 'fslightbox';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class Gallery {
  constructor() {
    this.swiperGallery = document.querySelector('.swiper-gallery');
  }

  init() {
    if (!this.swiperGallery) return;
    this.initGallery();
  }

  initGallery() {
    new Swiper(this.swiperGallery, {
      modules: [Navigation],
      spaceBetween: 16,
      slidesPerView: 1,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
      loop: true,
      navigation: {
        nextEl: '.swiper-gallery-next',
        prevEl: '.swiper-gallery-prev',
      },
    });
  }
}
