import Swiper from 'swiper';
import { Pagination, EffectFade, Navigation } from 'swiper/modules';

export default class Testminonials {
  constructor() {
    this.swiperTestimonials = document.querySelector('.swiper-testimonials')
    this.swiperTestimonials4 = document.querySelector('.swiper-testimonials-4')
    this.videos = document.querySelectorAll('[data-testimonial-video]');
  }

  init() {
    if (this.swiperTestimonials) {
      this.initTestimonials();
    }
    if (this.videos) {
      this.playVideo();
    }

    if(this.swiperTestimonials4) {
      this.initTestimonials4();
    }
  }

  initTestimonials() {
    new Swiper(this.swiperTestimonials, {
      modules: [Pagination, EffectFade, Navigation],
      loop: true,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.testimonial-button-next',
        prevEl: '.testimonial-button-prev',
      },
    });
  }

  playVideo() {
    this.videos.forEach((video) => {
      const playButton = video.parentElement.querySelector('[data-testimonial-play]');
      playButton.addEventListener('click', () => {
        video.play();
        playButton.dataset.pause = false;
      });
      video.addEventListener('click', () => {
        if (playButton.dataset.pause === 'false') {
          video.pause();
          playButton.dataset.pause = true;
        }
      });
    });
  }

  initTestimonials4() {
   let sliderTestimonials4 = new Swiper(this.swiperTestimonials4, {
      modules: [Navigation],
      slidesPerView: 1,
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
      navigation: {
        nextEl: '.testimonial4-button-next',
        prevEl: '.testimonial4-button-prev',
      },
    });
  }
}
